import React, { createContext, useCallback, useEffect, useState } from "react";
import axiosInstance from "../utils/axiosInstance";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    loading: true,
    user: null,
    error: null  // 에러 상태 추가
  });

  const clearError = () => {
    setAuth(prev => ({ ...prev, error: null }));
  };

  const loadUser = useCallback(async () => {
    try {
      const res = await axiosInstance.get("/auth_v2/user");
      setAuth({
        isAuthenticated: true,
        loading: false,
        user: res.data,
        error: null
      });
    } catch (err) {
      console.log("로그인되지 않음");
      setAuth({
        isAuthenticated: false,
        loading: false,
        user: null,
        error: null
      });
    }
  }, []);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  const login = async (email, password) => {
    clearError();  // 로그인 시도 전 에러 초기화
    try {
      await axiosInstance.post("/auth_v2/login", { email, password });
      await loadUser();  // 로그인 후 사용자 정보를 다시 로드
      return { success: true };
    } catch (err) {
      console.error("로그인 실패:", err.response?.data);
      let errorMessage = "로그인에 실패했습니다. 이메일과 비밀번호를 확인해주세요.";
      if (err.response?.status === 429) {
        errorMessage = "너무 많은 요청이 있었습니다. 잠시 후 다시 시도해주세요.";
      } else if (err.response?.data?.message) {
        errorMessage = err.response.data.message;
      }
      setAuth(prev => ({ ...prev, error: errorMessage }));
      return { success: false, error: errorMessage };
    }
  };

  const logout = async () => {
    clearError();  // 로그아웃 시도 전 에러 초기화
    try {
      await axiosInstance.post('/auth_v2/logout');
      setAuth({
        isAuthenticated: false,
        loading: false,
        user: null,
        error: null
      });
    } catch (err) {
      console.error("로그아웃 실패:", err);
      setAuth(prev => ({ ...prev, error: "로그아웃 중 오류가 발생했습니다." }));
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, clearError }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
