import { ChevronDown, LogOut, Menu, Navigation, ShieldCheck, TestTubes, User, X } from "lucide-react";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context";
import "./styles.css";

const Header = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const siteName = process.env.REACT_APP_WEBSITE_NAME || "KOACA";

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setOpenDropdown(null);
  };

  const toggleDropdown = (dropdownName) => (e) => {
    e.preventDefault();
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleDropdownItemClick = () => {
    setOpenDropdown(null);
    setIsMobileMenuOpen(false);
  };

  const navItems = [
    {
      name: "실험실 & 놀이터",
      icon: <TestTubes size={16} />,
      dropdownItems: [{ to: "/stock", text: "주식 목록" }]
    },
    {
      name: "핵심 페이지",
      icon: <Navigation size={16} />,
      dropdownItems: [
        { to: "/calendar", text: "캘린더" },
        { to: "/rental", text: "대여 관리" },
        { to: "/academy-club/list", text: "그룹 목록" }
      ]
    },
    {
      name: "내 정보",
      icon: <User size={16} />,
      dropdownItems: [
        { to: "/profile", text: "프로필" },
        { to: "/profile/cv", text: "상세 프로필 수정" },
        { to: "/profile/realcv", text: "상세 프로필 공유" }
      ]
    },
    {
      name: "증명 및 지원",
      icon: <ShieldCheck size={16} />,
      dropdownItems: [
        { to: "/profile/verify", text: "내 신원 증명" },
        { to: "/academy-club/verify", text: "내 그룹 증명" },
        { to: "/academy-club/recruitment/applications", text: "그룹 지원서" }
      ]
    }
  ];

  return (
    <header className="header">
      <div className="header-content">
        <Link to="/" className="logo">
          {siteName}
        </Link>
        <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <X /> : <Menu />}
        </button>
        <nav className={`main-nav ${isMobileMenuOpen ? 'open' : ''}`}>
          <ul>
            {navItems.map((item, index) => (
              <li key={index} className="nav-item">
                <button
                  className="nav-link dropdown-toggle"
                  onClick={toggleDropdown(item.name)}
                  aria-expanded={openDropdown === item.name}
                  aria-haspopup="true"
                >
                  {item.icon}
                  <span>{item.name}</span>
                  <ChevronDown size={16} />
                </button>
                {openDropdown === item.name && (
                  <div className="nav-dropdown">
                    {item.dropdownItems.map((dropdownItem, dropdownIndex) => (
                      <Link key={dropdownIndex} to={dropdownItem.to} onClick={handleDropdownItemClick}>
                        {dropdownItem.text}
                      </Link>
                    ))}
                  </div>
                )}
              </li>
            ))}
            {!auth.isAuthenticated ? (
              <>
                <Link to="/login" className="nav-link" onClick={toggleMobileMenu}><User size={16} /> 로그인</Link>
                <Link to="/register" className="nav-link" onClick={toggleMobileMenu}>회원가입</Link>
              </>
            ) : (
              <button onClick={() => { handleLogout(); toggleMobileMenu(); }} className="logout-button">
                <LogOut size={16} /> 로그아웃
              </button>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;