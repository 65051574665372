import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { auth } = useContext(AuthContext);

  if (auth.loading) {
    // console.log("ProtectedRoute 로딩 중...");
    return <div>Loading...</div>;
  }

  if (!auth.isAuthenticated) {
    // console.log("ProtectedRoute 인증되지 않음, 로그인 페이지로 이동");
    return <Navigate to="/login" />;
  }

  // console.log("ProtectedRoute 인증됨, 컴포넌트 렌더링");
  return <Component {...rest} />;
};

export default ProtectedRoute;