import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || '/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  }
});

// CSP nonce 추가 (서버에서 제공하는 경우)
const nonce = document.querySelector('meta[name="csp-nonce"]')?.getAttribute('content');
if (nonce) {
  axiosInstance.defaults.headers.common['X-CSP-Nonce'] = nonce;
}

// CSRF 토큰 추가 (서버에서 제공하는 경우)
const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
if (csrfToken) {
  axiosInstance.defaults.headers.common['X-CSRF-Token'] = csrfToken;
}

// 인터셉터 추가
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 429) {
      console.log('Rate limit exceeded. Please try again later.');
      // 여기에 사용자에게 알림을 표시하는 로직을 추가할 수 있습니다.
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;