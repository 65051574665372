import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "../App.css";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import ErrorBoundary from "../components/error/ErrorBoundary";
import { AuthProvider } from "../context";
import MainRoutes from "./MainRoutes";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <Header />
          <div className="main-content">
            <ErrorBoundary>
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  {MainRoutes.map((route, index) => (
                    <Route 
                      key={index} 
                      path={route.path} 
                      element={route.element}
                    >
                      {route.children && route.children.map((childRoute, childIndex) => (
                        <Route
                          key={childIndex}
                          path={childRoute.path}
                          element={childRoute.element}
                        />
                      ))}
                    </Route>
                  ))}
                </Routes>
              </Suspense>
            </ErrorBoundary>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;
