import { AlertTriangle, RefreshCw } from 'lucide-react';
import React, { useEffect, useState } from 'react';

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const errorHandler = (error, errorInfo) => {
      console.error("Error caught by ErrorBoundary: ", error, errorInfo);
      setHasError(true);
    };

    window.addEventListener('error', errorHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (hasError) {
    return <ErrorPage />;
  }

  return children;
};

const ErrorPage = () => {
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          window.location.reload();
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };

  const styles = {
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      backgroundColor: 'var(--ku-background)',
      padding: 'var(--ku-spacing-xl)',
      fontFamily: 'var(--ku-font-family)',
    },
    errorTitle: {
      fontSize: 'var(--ku-font-size-xlg)',
      marginBottom: 'var(--ku-spacing-lg)',
      color: 'var(--ku-primary)',
      fontWeight: 'var(--ku-font-weight-bold)',
    },
    errorMessage: {
      fontSize: 'var(--ku-font-size-md)',
      marginBottom: 'var(--ku-spacing-xl)',
      color: 'var(--ku-text-secondary)',
    },
    refreshButton: {
      display: 'flex',
      alignItems: 'center',
      gap: 'var(--ku-spacing-sm)',
      fontSize: 'var(--ku-font-size-md)',
      color: 'var(--ku-text-on-primary)',
      backgroundColor: 'var(--ku-primary)',
      border: 'none',
      textDecoration: 'none',
      padding: 'var(--ku-spacing-sm) var(--ku-spacing-md)',
      borderRadius: 'var(--ku-border-radius-md)',
      cursor: 'pointer',
      transition: 'background-color var(--ku-transition-normal), color var(--ku-transition-normal)',
    },
  };

  return (
    <div style={styles.errorContainer}>
      <AlertTriangle size={64} color="var(--ku-primary)" />
      <h1 style={styles.errorTitle}>
        페이지 로딩에 문제가 생겼습니다
      </h1>
      <p style={styles.errorMessage}>
        새로고침을 통해 문제를 해결해보세요.<br />
        {countdown}초 후 자동으로 새로고침됩니다.
      </p>
      <button onClick={handleRefresh} style={styles.refreshButton}>
        <RefreshCw size={18} />
        지금 새로고침
      </button>
    </div>
  );
};

export default ErrorBoundary;