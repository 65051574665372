import React, { lazy } from "react";
import ProtectedRoute from "./ProtectedRoute";

// 어드민
const UserVerification = lazy(() => import("../pages/essential/Admin/UserVerification"));
const AcademyClubVerification = lazy(() => import("../pages/essential/Admin/AcademyClubVerification"));
const AdminNotice = lazy(() => import("../pages/essential/Admin/Notice"));
// 홈
const Home = lazy(() => import("../pages/essential/Home/Home"));
// 로그인, 회원가입, 신원 확인
const Login = lazy(() => import("../pages/essential/Login/Login"));
const Register = lazy(() => import("../pages/essential/Register/Register"));
const VerifyIdentity = lazy(() => import("../pages/essential/Verify"));
// 프로필
const Profile = lazy(() => import('../pages/Profile_v2/Profile'));
const RealCV = lazy(() => import("../pages/Profile_v2/RealCV"));
const ProfileCV_v2 = lazy(() => import("../pages/Profile_v2/ProfileCV"));
const RealCVPublic = lazy(() => import("../pages/Profile_v2/RealCVPublic"));
// 캘린더
const CalendarList = lazy(() => import("../pages/Calendar/CalendarList"));
const CalendarCreate = lazy(() => import("../pages/Calendar/CalendarCreate/CalendarCreate"));
const CalendarEventsCreate = lazy(() => import("../pages/Calendar/CalendarEventsCreate/CalendarEventsCreate"));
const CalendarDetail = lazy(() => import("../pages/Calendar/CalendarDetail"));
const CalendarMain = lazy(() => import("../pages/Calendar_v2/CalendarMain"));
const CalendarShare = lazy(() => import("../pages/Calendar_v2/CalendarShare"));
// 대여
const RentalUser = lazy(() => import("../pages/Rent/RentalUser"));
const RentalListManager = lazy(() => import("../pages/Rent/RentalListManager"));
const RentalManager = lazy(() => import("../pages/Rent/RentalManager"));
// 공지사항
const UserNotice = lazy(() => import("../pages/essential/Notice/Notice"));
// 학회 및 동아리 Not Found 페이지
const AcademyClubNotFound = lazy(() => import("../pages/AcademyClub_v2/AcademyClubUser/404"));
const AcademyClubList = lazy(() => import("../pages/AcademyClub_v2/AcademyClubBoard/AcademyClubList"));
const VerifyAcademyClub = lazy(() => import("../pages/essential/Verify/VerifyAcademyClub"));
const AcademyClubv2 = lazy(() => import("../pages/AcademyClub_v2/AcademyClubUser/AcademyClubUser"));
const AcademyClubManager_v2  = lazy(() => import("../pages/AcademyClub_v2/AcademyClubManager/AcademyClubManager"));
const AcademyClubRecruitManager = lazy(() => import("../pages/AcademyClub_v2/AcademyClubRecruit/AcademyClubRecruitManager"));
const AcademyClubRecruitManagerJudge = lazy(() => import("../pages/AcademyClub_v2/AcademyClubRecruit/AcademyClubRecruitManagerJudge"));
const AcademyClubRecruitUser = lazy(() => import("../pages/AcademyClub_v2/AcademyClubRecruit/AcademyClubRecruitUser"));
const UserApplicationsList = lazy(() => import("../pages/AcademyClub_v2/AcademyClubRecruit/UserApplicationsList"));
// 주식
const StockUser = lazy(() => import("../pages/Stock/StockUser"));
const StockAdmin = lazy(() => import("../pages/Stock/StockAdmin"));
// 전체 404 Not Found 페이지
const NotFound = lazy(() => import("../components/error/NotFound"));


const routes = [
  { path: "/", element: <Home /> },
  { path: "/register", element: <Register /> },
  { path: "/login", element: <Login /> },

  { path: "/notice", element: <UserNotice /> },

  { path: "/profile/verify", element: <ProtectedRoute component={VerifyIdentity} /> },
  { path: "/profile", element: <ProtectedRoute component={Profile} /> },
  { path: "/profile/cv", element: <ProtectedRoute component={ProfileCV_v2} /> },
  { path: "/profile/realcv", element: <ProtectedRoute component={RealCV} /> },
  { path: "/profile/realcv/:userId", element: <RealCVPublic /> },

  { path: "/calendar", element: <ProtectedRoute component={CalendarMain} /> },
  { path: "/calendar/share", element: <ProtectedRoute component={CalendarShare} /> },
  { path: "/calendar/list", element: <ProtectedRoute component={CalendarList} /> },
  { path: "/calendar/create", element: <ProtectedRoute component={CalendarCreate} /> },
  { path: "/calendar/:calendarId", element: <ProtectedRoute component={CalendarDetail} /> },
  { path: "/calendar/edit/:calendarId", element: <ProtectedRoute component={CalendarEventsCreate} /> },

  { path: "/rental", element: <ProtectedRoute component={RentalListManager} /> },
  { path: "/rental/:rentalId", element: <RentalUser /> }, // ProtectedRoute 제거
  { path: "/rental/manager/:rentalId", element: <ProtectedRoute component={RentalManager} /> },

  { path: "/stock", element: <StockUser /> },
  
  { path: "/academy-club/notfound", element: <AcademyClubNotFound /> },
  { path: "/academy-club/verify", element: <ProtectedRoute component={VerifyAcademyClub} /> },
  { path: "/academy-club/list", element: <AcademyClubList /> },
  { path: "/academy-club/main/:academyclubName", element: <AcademyClubv2 /> },
  { path: "/academy-club/manager/main/:academyClubName", element: <ProtectedRoute component={AcademyClubManager_v2} /> },
  { path: "/academy-club/manager/recruitment/:academyClubName", element: <ProtectedRoute component={AcademyClubRecruitManager} /> },
  { path: "/academy-club/manager/recruitment/:academyClubName/:recruitmentId", element: <ProtectedRoute component={AcademyClubRecruitManagerJudge} /> },
  { path: "/academy-club/recruitment/:academyClubName/:recruitmentId", element: <ProtectedRoute component={AcademyClubRecruitUser} /> },
  { path: "/academy-club/recruitment/applications", element: <ProtectedRoute component={UserApplicationsList} /> },
  
  { path: "/admin/notice", element: <ProtectedRoute component={AdminNotice} /> },
  { path: "/admin/stock", element: <ProtectedRoute component={StockAdmin} /> },
  { path: "/admin/userverification", element: <ProtectedRoute component={UserVerification} /> },
  { path: "/admin/academyclubverification", element: <ProtectedRoute component={AcademyClubVerification} /> },
  { path: "*", element: <NotFound /> },
];


export default routes;